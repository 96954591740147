import InputPublic from "../../components/inputPublic";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ValidateEmailField, ValidateTextField} from "../../utils/validation";
import InputFile from "../../components/inputFile";
import TextArea from "../../components/textArea";
import {AddAdvertising} from "./advertising.actions";
import InputCheckbox from "../../components/inputCheckbox";

const AdvertisingCreate = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [nameError, setNameError] = useState("");
    const [description, setDescription] = useState('');
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [descriptionError, setDescriptionError] = useState("");
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        switch (name){
            case "name":
                setName(value);
                const nameValidationResult = ValidateTextField(value);
                setNameValid(nameValidationResult.isValid);
                setNameError(nameValidationResult.message);
                break;
            case "description":
                setDescription(value);
                const descriptionValidationResult = ValidateTextField(value);
                setDescriptionValid(descriptionValidationResult.isValid);
                setDescriptionError(descriptionValidationResult.message);
                break;
        }
    }

    const onHandleSubmit = () => {
        let validationResult = validateForm();
        if (validationResult === true) {
            dispatch(AddAdvertising(file, name, description, isActive, history));
        }
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0){
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    }

    function validateForm(){
        let emptyFields = false;
        if (!name){
            setNameValid(false);
            setNameError("Не указано название");
            emptyFields = true;
        }

        if (!description){
            setDescriptionValid(false);
            setDescriptionError("Не указано описание");
            emptyFields = true;
        }

        return !emptyFields && nameValid && descriptionValid;
    }

    return(
        <div className="c-banners">
            <h3>Акции / Добавить акцию</h3>
            <form id="c-entry__login" className="c-entry__edit-form">
                <div className="c-entry__line">
                    <InputPublic
                        title={'Название'}
                        name={'name'}
                        type={'text'}
                        placeholder={''}
                        error={!nameValid}
                        errorText={nameError}
                        data={name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="c-entry__line">
                    <TextArea title={'Описание'} data={description} name={'description'} onChange={handleInputChange}
                              error={!descriptionValid} errorText={descriptionError} isLarge={true}/>
                </div>
                <div className="c-entry__line">
                    <label className="e-label">Фото акции (Пропорции 10х4)</label>
                </div>
                <div className="c-entry__line">
                    <InputFile fileName={fileName} onSelectFile={e => onSelectFile(e)}/>
                </div>
                <div className="c-entry__line">
                    <InputCheckbox title="Скрыть запись из каталогов" isSelected={!isActive} onChange={() => setIsActive(prevState => !prevState)}/>
                </div>
                <div className="c-entry__line c-entry__line--mt">
                    <button type="button" className="e-btn e-btn--filled" onClick={onHandleSubmit}>Добавить</button>
                </div>
            </form>
        </div>
    );

}

export default AdvertisingCreate;
